<template lang="pug">
	div
		loading(:active.sync="busy", :is-full-page="false")
		div(v-if="!busy && !click") Click not found
		div(v-if="click")
			.row
				.col-md-6
					.card.card-custom.gutter-b
						.card-header
							.card-title
								.card-label Click Info: {{ click.id }}
						.card-body
							.form.click-info
								.form-group.row
									label.col-sm-2.col-form-label.col-form-label-sm Click ID
									div.col-sm-10
										span.form-control.form-control-sm.form-control-solid {{ click.id }}
								.form-group.row
									label.col-sm-2.col-form-label.col-form-label-sm Advanced Privacy
									div.col-sm-10
										span.form-control.form-control-sm.form-control-solid {{ click.advanced_privacy }}
								.form-group.row
									label.col-sm-2.col-form-label.col-form-label-sm Timestamp
									div.col-sm-10
										span.form-control.form-control-sm.form-control-solid {{ click.created_at }}
								.form-group.row
									label.col-sm-2.col-form-label.col-form-label-sm Advertiser
									div.col-sm-10(style="line-height: 30px;")
										entity(type="advertiser", :id="click.advertiser.id", :name="click.advertiser.name")
								.form-group.row
									label.col-sm-2.col-form-label.col-form-label-sm Offer
									div.col-sm-10(style="line-height: 30px;")
										entity(type="offer", :id="click.offer.id", :name="click.offer.name")
								.form-group.row
									label.col-sm-2.col-form-label.col-form-label-sm Publisher
									div.col-sm-10(style="line-height: 30px;")
										entity(type="publisher", :id="click.publisher.id", :name="click.publisher.name")
								.form-group.row
									label.col-sm-2.col-form-label.col-form-label-sm Placement
									div.col-sm-10(style="line-height: 30px;")
										entity(type="placement", :id="click.placement.id", :name="click.placement.name")
								.form-group.row
									label.col-sm-2.col-form-label.col-form-label-sm Country
									div.col-sm-10
										span.form-control.form-control-sm.form-control-solid {{ click.country }}
								.form-group.row
									label.col-sm-2.col-form-label.col-form-label-sm Region
									div.col-sm-10
										span.form-control.form-control-sm.form-control-solid {{ click.region_id }}
								.form-group.row
									label.col-sm-2.col-form-label.col-form-label-sm City
									div.col-sm-10
										span.form-control.form-control-sm.form-control-solid {{ click.city_id }}
								.form-group.row
									label.col-sm-2.col-form-label.col-form-label-sm Appname
									div.col-sm-10(style="line-height: 30px;")
										entity(v-if="click.appname", type="appname", :id="click.appname.id", :name="click.appname.name")
								.form-group.row
									label.col-sm-2.col-form-label.col-form-label-sm Bundle
									div.col-sm-10(style="line-height: 30px;")
										entity(v-if="click.bundle", type="bundle", :id="click.bundle.id", :name="click.bundle.name")
								.form-group.row
									label.col-sm-2.col-form-label.col-form-label-sm Sub1
									div.col-sm-10(style="line-height: 30px;")
										entity(v-if="click.sub1", type="sub1", :id="click.sub1.id", :name="click.sub1.name")
								.form-group.row
									label.col-sm-2.col-form-label.col-form-label-sm Sub2
									div.col-sm-10(style="line-height: 30px;")
										entity(v-if="click.sub2", type="sub1", :id="click.sub2.id", :name="click.sub2.name")
								.form-group.row
									label.col-sm-2.col-form-label.col-form-label-sm AdvSub1
									div.col-sm-10(style="line-height: 30px;")
										entity(v-if="click.adv_sub1", type="adv_sub1", :id="click.adv_sub1.id", :name="click.adv_sub1.name")

			.row
				.col-md-12
					.card.card-custom.gutter-b
						.card-header
							.card-title
								.card-label Raw Data
						.card-body
							.row
								.col-md-6
									h4 Params
									pre {{ click.params }}
								.col-md-6
									h4 Headers
									pre {{ click.headers }}
			.row
				.col-md-12
					.card.card-custom.gutter-b
						.card-header
							.card-title
								.card-label Events
						.card-body
							table.table
								thead
									tr
										th ID
										th EventName
										th Timestamp
										th IsConversion
										th Revenue
										th Cost
										th Reported
										th Parameters
										th &nbsp;
								tbody
									tr(v-for="r in events")
										td {{ r.id }}
										td {{ r.event_name }}
										td {{ r.created_at }}
										td
											span(v-if="r.is_conversion")
												i.fas.fa-check
										td {{ r.revenue }}
										td {{ r.cost }}
										td
											span(v-if="r.is_reported")
												i.fas.fa-check
											span(v-if="r.is_reported") &nbsp; {{ r.report_time }}
										td
											pre.toggleable {{ r.postback_log.query_params }}
										td &nbsp;
			.row
				.col-md-12
					.card.card-custom.gutter-b
						.card-header
							.card-title
								.card-label Publisher Postbacks
						.card-body
							table.table.table-bordered
								thead
									tr
										th ID
										th Type
										th URL
										th Response
										th Created At
								tbody
									tr(v-if="publisher_postbacks.length===0")
										td(colspan="5") No matching records were found
									tr(v-for="r in publisher_postbacks", :key="r.id")
										td {{ r.id }}
										td {{ r.type }}
										td
											span.req-preview {{ r.url }}
										td
											span.req-preview {{ r.http_response }}
										td {{ r.created_at }}

</template>
<style lang="scss">
.click-info {
	.form-group.row {
		margin-bottom: 3px;
	}
}
</style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Vue from "vue";
async function loadRoute(vm, to, from, next){
	let clickId = to.params.id;
	if (!clickId){
		return next(false);
	}
	let click;
	let publisherPostbacks = [];
	let events = [];
	try {
		let resp = await Vue.$api.get('/clicks/get/'+clickId);
		if (resp.entity){
			click = resp.entity;
			publisherPostbacks = resp.publisher_postbacks;
			events = resp.events;
		} else {
			click = null;
		}
	} catch (e){
		console.error(e);
		Vue.$notify.error('Failed to load click');
		return next(false);
	}
	return vm => {
		vm.click = click;
		vm.publisher_postbacks = publisherPostbacks;
		vm.events = events;
		vm.busy = false;
		return vm;
	};
}
export default {
	name: 'ClickView',
	async beforeRouteEnter(to, from, next){
		let bind = await loadRoute({}, to, from, next);
		next(bind);
	},
	async beforeRouteUpdate(to, from, next){
		await loadRoute(this, to, from, next);
		next();
	},
	data() {
		return {
			busy: true,
			click: null,
			events: [],
			publisher_postbacks: []
		};
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [
			{ title: 'Media' },
			{ title: 'Reports' },
			{ title: 'Click View' }
		]);
	},
}
</script>
